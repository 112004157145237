import { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useGoogleReCaptcha } from '../../hooks/useGoogleReCaptcha/useGoogleReCaptcha';
import mergeOperations from '../../util/shallowMerge';
import { useCartContext, useCartState } from '../../context/cart';
import { useUserContext, useUserState } from '../../context/user';
import { useAwaitQuery } from '../useAwaitQuery';
import DEFAULT_OPERATIONS from './signIn.gql';
import BrowserPersistence from 'packages/framework/util/simplePersistence';
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { retrieveCartId } from 'packages/framework/store/actions/cart';
import { sendGtmEvent } from 'packages/util/gtm';

/**
 * Returns props necessary to render signIn component. In particular this
 * talon handles the submission flow by first doing a pre-submisson validation
 * and then, on success, invokes the `onSubmit` prop, which is usually the action.
 *
 * @param {Function} props.onSubmit the post submit callback
 *
 *
 */

const storage = new BrowserPersistence();

export const useSignIn = props => {
  const {
    getCartDetailsQuery,
    setDefaultUsername,
    showCreateAccount,
    showForgotPassword,
    onSubmit,
  } = props;

  const operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  const {
    createCartMutation,
    getCustomerQuery,
    mergeCartsMutation,
    signInMutation,
    loginOtpMutation,
    loginOtpVerifyMutation,
    userConsentMutation,
  } = operations;

  const apolloClient = useApolloClient();
  const [secondsRemaining, setSecondsRemaining] = useState(15);

  const { createCart, removeCart, getCartDetails, actions }: any =
    useCartContext();
  const { cartId } = useCartState();

  const { getUserDetails, setToken }: any = useUserContext();
  const { isGettingDetails, getDetailsError } = useUserState();
  const [isOTPAvailable, setOTPAvaialble] = useState(false);
  const [isAgree, setAgree] = useState(false);

  const [
    createLogintOtp,
    {
      data: loginOtpData,
      error: createLoginOtpError,
      loading: createLoginOtpLoading,
    },
  ] = useMutation(loginOtpMutation);

  const [
    signInConsent,
    {
      data: signInConsentStatus,
      loading: signInConsentLoading,
      error: signInConsentError,
    },
  ] = useMutation(userConsentMutation);

  const [
    createLoginOtpVerify,
    {
      data: createLoginOtpVerifyStatus,
      loading: createLoginOtpVerifyLoading,
      error: createLoginOtpVerifyError,
    },
  ] = useLazyQuery(loginOtpVerifyMutation);

  const { generateReCaptchaData, recaptchaLoading, recaptchaWidgetProps } =
    useGoogleReCaptcha({
      currentForm: 'CUSTOMER_LOGIN',
      formAction: 'signIn',
    });

  const [fetchCartId, { loading: fetchCartIdLoading }] =
    useMutation(createCartMutation);
  const [mergeCarts, { loading: mergeCartsLoading }] =
    useMutation(mergeCartsMutation);

  const fetchUserDetails = useAwaitQuery(getCustomerQuery);
  const fetchCartDetails = useAwaitQuery(getCartDetailsQuery);

  useEffect(() => {
    if (loginOtpData?.loginOTP?.status && !createLoginOtpLoading) {
      setOTPAvaialble(true);
      setSecondsRemaining(15);
      let intval = setInterval(() => {
        setSecondsRemaining(val => {
          return val - 1;
        });
      }, 1000);
      setTimeout(() => {
        // allowResend(true);
        clearInterval(intval);
      }, 16000);
    }
  }, [loginOtpData, createLoginOtpLoading, setSecondsRemaining]);

  const generateOtp = useCallback(
    mobileNumber => {
      createLogintOtp({
        variables: {
          mobileNumber: `91${mobileNumber}`,
        },
      });
    },
    [createLogintOtp],
  );

  const handleSubmitOTP = useCallback(
    async formValues => {
      try {
        const { data } = await createLogintOtp({
          variables: {
            mobileNumber: `91${formValues.mobilenumber}`,
          },
        });

        if (data?.loginOTP?.status) {
          return true;
        } else {
          alert(data?.loginOTP.message);
          return false;
        }
      } catch (error) {
        console.log('error', error);
        alert(error);
      }
    },
    [
      cartId,
      generateReCaptchaData,
      setToken,
      apolloClient,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      onSubmit,
      createLogintOtp,
      createLoginOtpVerify,
    ],
  );
  const handleVerifyOTP = useCallback(
    async formValues => {
      try {
        await signInConsent({
          variables: {
            mobile: `91${formValues.mobilenumber}`,
            user_consent: formValues.agree,
          },
        });

        const { data, error } = await createLoginOtpVerify({
          variables: {
            mobileNumber: `91${formValues.mobilenumber}`,
            otp: formValues.otp,
          },
        });
        setAgree(false);
        if (data?.loginOTPVerify.status) {
          // Get source cart id (guest cart id).
          const sourceCartId = cartId;

          const token = data?.loginOTPVerify?.token;

          await setToken(token);

          // Clear all cart/customer data from cache and redux.
          await (apolloClient as any).clearCacheData(apolloClient, 'cart');
          await (apolloClient as any).clearCacheData(apolloClient, 'customer');
          await removeCart();

          // Create and get the customer's cart id.
          await createCart({
            fetchCartId,
          });

          const destinationCartId = await retrieveCartId();

          // Merge the guest cart into the customer cart.
          await mergeCarts({
            variables: {
              destinationCartId,
              sourceCartId,
            },
            errorPolicy: 'ignore',
          });

          // Ensure old stores are updated with any new data.

          getUserDetails({ fetchUserDetails, mode: 'email' });

          getCartDetails({ fetchCartId, fetchCartDetails });

          // Finally, invoke the post-submission callback.
          if (token && onSubmit) {
            onSubmit();
          }
        } else {
          alert(data?.loginOTPVerify.message);
          sendGtmEvent('Login Error', {
            error_message: data?.loginOTPVerify.message,
            page_url: window.location.href,
          });
        }
        if (error) {
          sendGtmEvent('Login Error', {
            error_message: error.message,
            page_url: window.location.href,
          });
        }
      } catch (error) {
        console.log('error', error);
        sendGtmEvent('Login Error', {
          error_message: (error as Error).message,
          page_url: window.location.href,
        });
        alert(error);
      }
    },
    [
      cartId,
      generateReCaptchaData,
      setToken,
      apolloClient,
      createCart,
      fetchCartId,
      mergeCarts,
      getUserDetails,
      fetchUserDetails,
      getCartDetails,
      fetchCartDetails,
      onSubmit,
      createLogintOtp,
      createLoginOtpVerify,
    ],
  );

  const handleForgotPassword = useCallback(() => {
    // const { current: formApi } = formApiRef;

    // if (formApi) {
    //   setDefaultUsername(formApi.getValue("email"));
    // }

    showForgotPassword();
  }, [setDefaultUsername, showForgotPassword]);

  const handleCreateAccount = useCallback(() => {
    // const { current: formApi } = formApiRef;

    // if (formApi) {
    //   setDefaultUsername(formApi.getValue("email"));
    // }

    showCreateAccount();
  }, [setDefaultUsername, showCreateAccount]);

  const errors = useMemo(
    () =>
      new Map([
        // ['getUserDetailsQuery', getDetailsError],
        ['loginOtpVerifyMutation', createLoginOtpVerifyError],
        ['loginOtpMutation', createLoginOtpError],
      ]),
    [
      // getDetailsError,
      // signInError,
      createLoginOtpError,
      createLoginOtpVerifyError,
    ],
  );

  return {
    errors,
    handleCreateAccount,
    handleForgotPassword,
    isBusy:
      isGettingDetails ||
      createLoginOtpVerifyLoading ||
      fetchCartIdLoading ||
      mergeCartsLoading,
    recaptchaLoading,
    recaptchaWidgetProps,
    createLoginOtpLoading,
    createLoginOtpVerifyLoading,
    handleSubmitOTP,
    handleVerifyOTP,
    secondsRemaining,
    loginOtpData,
    isOTPAvailable,
    generateOtp,
    setAgree,
    isAgree,
  };
};
