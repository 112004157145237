import React from 'react';
import { bool, shape, string } from 'prop-types';
import { useScrollLock } from 'packages/framework/hooks/useScrollLock';

import { useStyle } from 'packages/framework/util/classify';

import defaultClasses from './main.module.css';
import HeaderTop from 'components/Header/HeaderTop';
import { useAppContext } from 'packages/framework/context/app';
import Header from 'components/Header';
import Footer from '../Footer';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { useLocation } from 'react-router';

const pageURLRemoveClass = [
  '/support',
  '/shipping-policy',
  '/cancellations-returns',
  '/sitemap',
  '/gourmet-by-atomberg',
];

const Main = (props: any) => {
  const location = useLocation();
  const { children, isMasked } = props;
  const classes = useStyle(defaultClasses, props.classes);

  const rootClass = isMasked ? classes.root_masked : classes.root;
  const pageClass = isMasked ? classes.page_masked : classes.page;

  // const { isMobile }= useWindowSize();

  useScrollLock(isMasked);

  const isClassRemove = pageURLRemoveClass.includes(location.pathname);

  const isMgLanding =
    location.pathname === '/mglanding/2' ||
    location.pathname === '/smartlock1' ||
    location.pathname === '/ceilingfan' ||
    location.pathname === '/landing/smartfans' ||
    location.pathname === '/gourmet-by-atomberg';

  const isHideFooter =
    location.pathname === '/ceilingfan' ||
    location.pathname === '/mglanding/2' ||
    location.pathname === '/gourmet-by-atomberg';
  return (
    <main className={rootClass}>
      {/* {!isMobile && <HeaderTop />} */}
      {!isMgLanding && <Header />}
      <div className={!isClassRemove ? pageClass : ''}>{children}</div>
      {!isHideFooter ? <Footer /> : null}
    </main>
  );
};

export default Main;

// Main.propTypes = {
//     classes: shape({
//         page: string,
//         page_masked: string,
//         root: string,
//         root_masked: string
//     }),
//     isMasked: bool
// };
