import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './footer.scss';
import { useUserState } from 'packages/framework/context/user';
import { useAppContext, useAppState } from 'packages/framework/context/app';

const baseURL = STORE_CONFIG.secure_base_media_url;
const dateNow = Date.now();
/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  discover: [
    { name: 'About us', href: '/about' },
    { name: 'Blogs', href: '/blog' },
    { name: 'Careers', href: '/careers' },
    { name: 'Quick Fix', href: '/quickfix' },
    { name: 'Track Your Order', href: '/account/orders', isTrackOrder: true },
    { name: 'FAQs', href: '/faq' },
    { name: 'Sitemap', href: '/sitemap' },
  ],
  policies: [
    { name: 'Customer Policy', href: '/customer-grievance-policy' },
    { name: 'Product Policy', href: '/terms-conditions' },
    { name: 'Vendor Policy', href: '/vendor-grievance-policy' },
    { name: 'Investor Relations', href: '/investor-relations' },
    {
      name: 'POSH',
      href: `${baseURL}pdf/Prevention-of-Sexual-Harassment-PoSH-Policy.pdf?v=${dateNow}`,
      media: true,
      isPdfFile: true,
    },
    {
      name: 'Code of Conduct',
      href: `${baseURL}pdf/Atomberg-Code-of-Conduct.pdf?v=${dateNow}`,
      media: true,
      isPdfFile: true,
    },
  ],
  contact: [
    {
      name: `WhatsApp: 809-745-4422`,
      href: 'tel:+918448449442',
      id: 'WhatsApp',
    },
    {
      name: 'Email: support@atomberg.com | salesenquiry@atomberg.com',
      id: 'Email',
      supportEmail: 'support@atomberg.com',
      salesEmail: 'salesenquiry@atomberg.com',
      href: 'mailto:support@atomberg.com,salesenquiry@atomberg.com',
      isEmail: true,
    },
    {
      name: [
        <span key="timing">Timings: 9.30 AM to 6.30 PM</span>,
        <span key="allDays" className="ml-1 md:ml-0 inline-block md:block">
          on all days except public holidays
        </span>,
      ],
      id: 'Timing',
    },
    {
      name: [
        <span key="expert">For expert help and customer complaints,</span>,
        <span key="phoneNumber" className="ml-1 md:ml-0 inline-block md:block">
          call: 844 844 9442
        </span>,
      ],
      id: 'telephone',
      href: 'tel:+918448449442',
    },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/gorillafans/',
      src: '/footer/fb-logo.svg',
    },
    {
      name: 'Youtube',
      href: 'https://www.youtube.com/channel/UCd7r6QLE7B-bG2_WwxLKL8w/videos',
      src: '/footer/youtube.svg',
    },
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/atomberg-technologies-private-limited/',
      src: '/footer/linkedin-logo.svg',
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/atomberg_tech?s=15',
      src: '/footer/twitter-logo.svg',
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/atomberg/?ref=badge',
      src: '/footer/instagram-logo.svg',
    },
    {
      name: 'Pinterest',
      href: 'https://in.pinterest.com/Atomberg1234/',
      src: '/footer/pinterest-logo.png',
    },
  ],
};
const pdfLink = ['POSH Policy', 'Code of Conduct'];

const SocialIcons = ({ socialIconData }) => {
  const { isDesktop } = useWindowSize();
  return socialIconData?.map((item, index) => (
    <a
      key={item.name}
      href={item.href}
      target="_blank"
      className="text-gray-400"
    >
      <span className="sr-only">{item.name}</span>
      <img
        className={`h-4 w-4 ${
          isDesktop && index == 0 ? 'w-8 h-8' : `${isDesktop ? 'w-6 h-6' : ''}`
        }`}
        src={item.src}
        alt={item?.name}
        width={16}
        height={16}
        loading='lazy'
      />
    </a>
  ));
};

const InternalLinks = ({ internalLinkData, isSubLinks = false, handleTrackOrderOnChange= (e: any) => {} }) => {
  return (
    <ul role="list" className={`mt-2 ${isSubLinks ? 'md:mt-4' : 'md:mt-4'}`}>
      {internalLinkData?.map(item => (
        <li
          key={item.name}
          className="leading-[1.2rem] md:leading-[1.8rem] md:min-w-[110px]"
        >
          {item.href ? (
            <Link
              to={item.href}
              target={`${item?.isPdfFile ? '_blank' : '_self'}`}
              className="text-[10px] md:text-[13px] text-[#ACACAC] font-normal hover:underline hover:text-[#868686]"
              onClick={(e) => !!item.isTrackOrder && handleTrackOrderOnChange(e)}
            >
              {item.name}
            </Link>
          ) : (
            <p className="text-[13px] text-[#ACACAC] font-normal">
              {item.name}
            </p>
          )}
        </li>
      ))}
    </ul>
  );
};

export default function NewFooter() {
  const { isMobile, isDesktop } = useWindowSize();
  const [showButton, setShowButton] = useState(false);
  const location = useLocation();
  const isNewMgLanding = location.pathname === '/mglanding/2';
  const isSmartLockLanding = location.pathname === '/smartlock1';
  const { isSignedIn } = useUserState();
  const { toggleDrawer, setHasToRedirect } = useAppContext();
  const { redirectUrl,allow } = useAppState();
  const navigate = useNavigate()

  useEffect(() => {
    const handleScrollButtonVisiblity = () => {
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener('scroll', handleScrollButtonVisiblity);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisiblity);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if(isSignedIn && redirectUrl && allow){
      navigate(redirectUrl);
      setHasToRedirect({ allow:false, redirectUrl:'' })
      handleScrollToTop()
    }
  },[isSignedIn,redirectUrl, allow])

  const handleTrackOrderOnChange = useCallback((e) => {
    if(!isSignedIn){
      e?.preventDefault()
      setHasToRedirect({redirectUrl: '/account/orders', allow:false })
      toggleDrawer('authModal.openClose');
    }else{
      handleScrollToTop();
    }
  },[isSignedIn, setHasToRedirect, toggleDrawer, handleScrollToTop])

  return (
    <footer className="bg-[#262626]" aria-labelledby="footer-heading">
      <div className={`scrollToTop ${showButton ? 'block' : 'hidden'}`}>
        <button
          className="fixed bottom-5 right-7 z-50 cursor-pointer p-4"
          onClick={handleScrollToTop}
        >
          <img
            src="/icons/new-up-arrow.png"
            alt="scrollToTop"
            className="w-[38px] h-[38px] aspect-1"
          />
        </button>
      </div>
      {/* TODO - need to get think from env  */}
      {/* TODO - vishal move this file to media/pdf instead of pub/media - change this post live */}
      {!isSmartLockLanding ? (
        <>
          <div className="call-to-action fixed justify-center right-0 top-[60%] w-[130px] lg:w-[140px] h-[44px] rounded-tl-[18px] rounded-bl-[18px] bg-white border-[1px] mt-[-50px] flex  items-center border-solid border-[#eee] z-[100] leading-[1] text-[14px] lg:text-[16px] pl-[4px]">
            <a className="mr-[12px]" target="_blank" rel="noreferrer noopener">
              <img
                src="https://media-dev.atomberg.com/media/wysiwyg/static_page_images/calls.png"
                className="w-[20px] h-[20px] aspect-1"
                alt="Call Us"
                loading="lazy"
              />
            </a>
            <div className="mobile-number">
              <a
                href="tel:8448449442"
                className="download-text hover:underline"
              >
                Call on <br /> 8448449442
              </a>
            </div>
          </div>
          <div className="whatsapp-us">
            <a className="" target="_blank" rel="noreferrer noopener">
              <img
                src="/icons/whatsapp.png"
                className="w-[24px] h-[24px] aspect-1"
                alt="Whats Up"
                loading="lazy"
              />
            </a>
            <div className="chat-with-us">
              <span>Chat with us</span>
              <a href={`https://wa.me/918097454422?text=hi`}>80974 54422</a>
            </div>
          </div>
        </>
      ) : null}
      <div className="container mx-auto">
        <div className="relative md:max-w-full mx-auto">
          <div className="max-w-[93%] mx-auto py-6 px-9 sm:px-6 lg:pt-16 lg:pb-0 lg:pl-8 lg:pr-0">
            <div className="flex gap-x-36 flex-col xl:flex-row">
              <div className="space-y-5 sm:space-y-7 basis-full xl:basis-[32%]">
                <Link to="/">
                  <img
                    className="footer-logo min-w-[170px] max-w-[170px] aspect-[4.3] object-contain"
                    src="/logo/atomberg-logo-white.webp"
                    alt="Atomberg"
                    loading="lazy"
                  />
                </Link>
                <div
                  className="company-details flex flex-col text-[#ACACAC] text-[10px] 
                md:text-[13px] leading-[1.2rem] md:leading-[1.8rem]"
                >
                  <span className="identity-number">
                    <span className="inline-block">Name of the Company:- </span>
                    <span className="font-semibold inline-block align-middle ml-[3px] xl:ml-0">
                      ATOMBERG TECHNOLOGIES PRIVATE LIMITED
                    </span>
                  </span>
                  <span className="company-address">
                    Registered office address:-
                    <address className="font-semibold not-italic">
                      Office No. 1205, 12th Floor, Rupa Solitaire, Millennium
                      Business Park,Thane-Belapur Road, Mahape, Navi Mumbai-
                      400710
                    </address>
                  </span>
                  <span className="company-address block mt-[0.2rem]">
                    Corporate office address:-{' '}
                    <address className="font-semibold not-italic">
                      12B107, 12A108, 12th Floor, 247 Park, WeWork, Lal Bahadur
                      Shastri Rd, Gandhi Nagar, Vikhroli, Mumbai, Maharashtra-
                      400079
                    </address>
                  </span>
                  <span className="identity-number">
                    Corporate identity number:-{' '}
                    <span className="font-semibold">U72900MH2012PTC229788</span>
                  </span>
                </div>
                <div className="hidden space-x-6 md:flex items-center">
                  <SocialIcons socialIconData={navigation?.social} />
                </div>
              </div>
              <div className="mt-12 md:grid md:grid-cols-3 md:gap-6 basis-full xl:basis-[63%]">
                <div>
                  <p className="text-[12px] md:min-w-[110px] md:text-[14px] font-bold text-[#ACACAC] tracking-wider uppercase">
                    Discover us
                  </p>
                  <InternalLinks internalLinkData={navigation?.discover} handleTrackOrderOnChange={handleTrackOrderOnChange} 
                  />
                </div>
                <div className="mt-5 md:mt-0">
                  <p className="text-[12px] md:min-w-[110px] md:text-[14px] font-bold text-[#ACACAC] tracking-wider uppercase">
                    Policies
                  </p>
                  <InternalLinks
                    internalLinkData={navigation?.policies}
                    isSubLinks={true}
                  />
                </div>
                <div className="mt-6 md:mt-0">
                  <div className="mt-6 md:mt-0">
                    <p className="text-[12px] md:text-[14px] font-bold text-[#ACACAC] tracking-wider uppercase">
                      Contact Us/ Support
                    </p>
                    <ul role="list" className="mt-2 md:mt-4">
                      {navigation.contact.map((item, index) => {
                        if (index == 0 && isDesktop) return null;
                        return (
                          <li
                            key={item?.id}
                            className="leading-[1rem] md:leading-[1.8rem] text-[#ACACAC] text-[10px] 
                            md:text-[13px] font-normal mb-2 md:mb-0"
                          >
                            {!item?.isEmail && item.href ? (
                              <a
                                href={item.href}
                                target="_blank"
                                className={`hover:underline hover:text-[#868686] inline-block 
                             `}
                              >
                                {item?.name}
                              </a>
                            ) : (
                              <>
                                {item?.isEmail ? (
                                  <div key={index}>
                                    <a
                                      href={`mailto:${item?.supportEmail}`}
                                      target="_blank"
                                    >
                                      Email: {item?.supportEmail}
                                    </a>
                                    <a
                                      href={`mailto:${item?.salesEmail}`}
                                      target="_blank"
                                      className="md:block"
                                    >
                                      <span className="inline-block mx-1 md:hidden">
                                        |
                                      </span>
                                      {item?.salesEmail}
                                    </a>
                                  </div>
                                ) : (
                                  <p key={index}>{item?.name}</p>
                                )}
                              </>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    <div className="flex items-center space-x-6 mt-3 md:hidden">
                      <SocialIcons socialIconData={navigation?.social} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-around pt-5 md:pt-5 md:pb-2">
              <p className="text-[10px] md:text-[13px] text-gray-400 xl:text-center">
                &copy; 2024. ALL RIGHTS RESERVED.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
