import { useQuery } from '@apollo/client';
import { string } from 'prop-types';

export const useItems = props => {
  const { inputText, query, queryName } = props;

  const { loading, error, data } = useQuery(query, {
    variables: { inputText },
    fetchPolicy: 'no-cache',
  });

  return {
    data: data ? data[queryName] || data : {},
    loading,
    error,
  };
};

useItems.propTypes = {
  inputText: string,
  query: string,
  queryName: string,
};
