import { gql } from '@apollo/client';

export const GET_SEARCH_PRODUCTS = gql`
  query getSearchProducts($inputText: String) {
    xsearchProducts(search: $inputText) {
      total_count
      items {
        __typename
        id
        type_id
        name
        sku
        variants {
          attributes {
            code
            value_index
            label
            uid
          }
          product {
            uid
            only_x_left_in_stock
            # eslint-disable-next-line @graphql-eslint/require-id-when-available

            media_gallery_entries {
              uid
              disabled
              file
              label
              position
              video_content {
                media_type
                video_provider
                video_url
                video_title
                video_description
                video_metadata
              }
            }
            speed
            steel_jars
            chopper_jars
            warranty
            consumption_in_watts
            air_delivery
            rpm
            service_value
            input_voltage
            frequency
            power_factor
            no_of_blades
            sku
            stock_status
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
            }
            custom_attributes {
              selected_attribute_options {
                attribute_option {
                  uid
                  label
                  is_default
                }
              }
              entered_attribute_value {
                value
              }
              attribute_metadata {
                uid
                code
                label
                attribute_labels {
                  store_code
                  label
                }
                data_type
                is_system
                entity_type
                ui_input {
                  ui_input_type
                  is_html_allowed
                }
                ... on ProductAttributeMetadata {
                  used_in_components
                }
              }
            }
          }
          __typename
        }
        configurable_options {
          attribute_code
          attribute_id
          uid
          label
          position
          # eslint-disable-next-line @graphql-eslint/require-id-when-available
          values {
            uid
            default_label
            label
            store_label
            use_default_value
            value_index
            swatch_data {
              ... on ImageSwatchData {
                thumbnail
              }
              value
            }
          }
        }
        small_image {
          url
        }
        rating_summary
        reviews_count
        price {
          minimalPrice {
            amount {
              value
            }
          }
          maximalPrice {
            amount {
              value
            }
          }
          regularPrice {
            amount {
              value
            }
          }
        }

        price_range {
          minimum_price {
            regular_price {
              currency
              value
            }
            final_price {
              currency
              value
            }
            discount {
              percent_off
              amount_off
            }
          }
          maximum_price {
            regular_price {
              currency
              value
            }
            final_price {
              currency
              value
            }
            discount {
              percent_off
              amount_off
            }
          }
        }
        special_price
        special_from_date
        special_to_date
        attribute_set_id
        is_salable
        image {
          url
        }
        url_key
        url_suffix
        url_path
        options_container
        tier_price
        manufacturer
        url_rewrites {
          url
        }
      }
      code
    }
  }
`;

export default {
  GET_SEARCH_PRODUCTS: GET_SEARCH_PRODUCTS,
};
