/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, Popover, Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/solid';
import './header.scss';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import CartTrigger from './cartTrigger';
import AuthModal from '../AuthComponent/AuthModal';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext, useAppState } from 'packages/framework/context/app';
import { useAuthModal } from '../AuthComponent/useAuthModal';
import { useUserState } from 'packages/framework/context/user';
import GlobalSearch from 'src/components/GlobalSearch';

const navigationDesktop = [
  ...NAVIGATION,
  {
    name: 'Register Warranty',
    url_key: '/warranty',
  },
  {
    name: 'Support',
    url_key: '/support',
  },
  { name: 'Store Locator', url_key: '/store-locator' },
];

const MobileLink = item => {
  const {
    name,
    url_key,
    children,
    classes,
    isLoginLink,
    isCategory,
    currentUser,
    handleAuthModal,
    isSignedIn,
    open,
    hasChildren,
    uniqueKey,
  } = item;
  const className = 'ml-0 px-[0.7rem] leading-6';
  return (
    <li
      key={uniqueKey}
      className={`${
        isCategory ? (url_key ? '' : 'py-4') : 'py-2'
      } flex uppercase justify-between items-center rounded-lg
        ${classes || ''}`}
      style={{
        ...(open
          ? {
              color: '#fdb913',
              borderRadius: 0,
            }
          : {color:'#1A1819'}),
      }}
    >
      {url_key ? (
        <Link
          to={`${url_key}${CATEGORY_URL_SUFFIX}`}
          className={`${className} ${
            hasChildren ? 'py-0' : 'py-4'
          } w-full hover:underline hover:text-[#fcb633]`}
        >
          {name}
        </Link>
      ) : (
        <div className="px-3">
          <span>{name}</span>
        </div>
      )}
      {children}
    </li>
  );
};

const MenuItemContainer = props => {
  const { item, uniqueKey } = props;
  const { isSignedIn, currentUser } = useUserState();
  const { toggleDrawer } = useAppContext();
  const { isDesktop } = useWindowSize();
  const hasChildren = Boolean(item?.items) && item?.items?.length > 0;

  const handleAuthModal = useCallback(() => {
    toggleDrawer('authModal.openClose');
  }, [toggleDrawer]);

  if (isDesktop) {
    return (
      <div
        className="group relative  z-20 items-center font-medium text-white hover:text-[#FDB913] tracking-wide py-7"
        key={uniqueKey}
      >
        <>
          <Link
            to={`${item.url_key}${CATEGORY_URL_SUFFIX}`}
            className={`${
              item?.url_key
                ? 'cursor-pointer'
                : 'pointer-events-none cursor-default'
            } hover:[#FDB913]`}
          >
            <span className="text-[10px] 2xl:text-[16px] inline-block uppercase leading-[1rem] xl:leading-[1.2rem] text-center xl:text-left">
              {item.name}
            </span>
          </Link>
        </>
        {item.items ? (
          <ul
            className="menuList group-hover:block absolute  top-full hidden max-h-[400px] overflow-auto nav-items bg-[#fea829] 
          w-48 shadow-md rounded-b-md "
          >
            {item?.items?.map((item, index) => (
              <Link
                key={index}
                to={`${item.url_key}${CATEGORY_URL_SUFFIX}`}
                className="-m-3 p-3 md:py-[16px] cursor-pointer md:px-0 md:m-0 block hover:underline hover:decoration-black"
              >
                <p className="text-base font-medium text-[#495057] md:text-[14px] md:leading-6 px-[0.7rem]">
                  {item.name}
                </p>
              </Link>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }

  return (
    <Disclosure key={item.name}>
      {({ open }) => (
        <>
          {hasChildren ? (
            <Disclosure.Button className="text-left">
              <MobileLink
                {...item}
                classes="nav-category px-6 text-[15px] "
                isCategory={true}
                handleAuthModal={handleAuthModal}
                currentUser={currentUser}
                isSignedIn={isSignedIn}
                open={!!open && !!hasChildren}
              >
                <ChevronDownIcon
                  className={`${
                    open ? 'rotate-180' : ''
                  } w-5 h-5 ml-2 px- -mr-1`}
                  aria-hidden="true"
                  fill="#fdb913"
                />
              </MobileLink>
            </Disclosure.Button>
          ) : (
            <MobileLink
              {...item}
              classes="nav-category px-6 text-[15px] "
              isCategory={true}
              handleAuthModal={handleAuthModal}
              currentUser={currentUser}
              isSignedIn={isSignedIn}
              open={!!open && !!hasChildren}
            />
          )}
          {hasChildren ? (
            <Disclosure.Panel className="pb-2 px-12 flex items-center flex-col justify-center mt-[5px]">
              {item?.items.map((item, index) => (
                <MobileLink
                  {...item}
                  key={index}
                  uniqueKey={index}
                  hasChildren={hasChildren}
                  classes="text-[12px] text-[#1A1819]"
                />
              ))}
            </Disclosure.Panel>
          ) : null}
        </>
      )}
    </Disclosure>
  );
};

const CloseMobileNav = props => {
  const { isMobile, isTablet } = useWindowSize();
  const { pathname } = useLocation();
  useEffect(() => {
    if (isMobile || isTablet) {
      props.close();
    }
  }, [pathname, isMobile, isTablet]);

  return null;
};

export default function NewHeader() {
  const { isMobile, isDesktop } = useWindowSize();
  const [isSearchShow, setSearchShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isSignedIn, currentUser } = useUserState();
  const location = useLocation();
  const isCheckout =
    location.pathname.includes('checkout') &&
    !location.pathname.includes('checkout/order-received') &&
    !location.pathname.includes('checkout/order-failed');
  const isMgLanding = location.pathname.includes('mglanding');
  const [isPopShow, setPopShow] = useState(false);
  const isCeilingFanLanding = location.pathname.includes('ceilingfan');
  const { toggleDrawer, setHasToRedirect } = useAppContext();
  const talonProps = useAuthModal();
  const { handleClose, isOpen } = talonProps;
  const { drawer,redirectUrl,allow } = useAppState();
  const navigate = useNavigate()
  // Need to change modal state handle
  // Not using this, handle this via {drawer} app context - Just for reference, remove it later - TODO

  const handleAuthModal = useCallback(() => {
    toggleDrawer('authModal.openClose');
    setPopShow(false);
  }, [toggleDrawer]);

  const btnClassName =
    'w-full cursor-pointer flex px-4 text-[15px] text-[#1A1819]';

  const handleSearchClick = () => {
    setSearchShow(prev => !prev);
  };

  const handleSearchClose = () => {
    setSearchShow(false);
  };

  useEffect(() => {
    handleSearchClose();
    setPopShow(false);
  }, [location.pathname]);

  useEffect(() => {
    if(isSignedIn && redirectUrl && allow){
      navigate(redirectUrl);
      setHasToRedirect({ allow:false, redirectUrl:'' })
    }
  },[isSignedIn,redirectUrl, allow])

  const handlePop = useCallback(() => {
    setPopShow(prev => !prev);
  }, [setPopShow]);

  const handlePopDesktop = useCallback(
    flag => {
      if (isDesktop) setPopShow(flag);
    },
    [setPopShow, isDesktop],
  );

  return (
    <>
      <header
        className={`${!isMobile ? 'stickyElement' : 'stickyElementMobile'}`}
      >
        <div className="container mx-auto">
          <div
            className={`flex justify-between items-center py-4 md:py-7 px-2 lg:px-4 md:px-0 lg:space-x-4 relative 
              md:max-w-full mx-auto`}
          >
            {/* Header Logo Start */}
            <div className="items-center">
              <Link to="/" className="flex">
                <span className="sr-only">Atomberg</span>
                {/* todo */}
                <img
                  className="atomgberg-logo h-[48px] w-auto md:h-[48px] md:w-[225px] 2xl:h-[70px] 2x:w-[250px]"
                  src="/logo/atomberg-highres-white-logo.svg"
                  alt="Atomberg"
                />
              </Link>
            </div>
            {/* Header Logo End */}

            {isCheckout ? null : (
              <Popover className="menu-wrapper static flex items-center lg:basis-[75.5%]">
                {({ open, close }) => (
                  <>
                    <CloseMobileNav close={close} open={open} />
                    <div className="flex justify-between">
                      {/* Cart and hamburger icon only show on mobile Start */}
                      {/* Cart and hamburger icon only show on mobile Start */}

                      {/* Header Menu Desktop Start */}
                      {/* Header Menu Desktop End */}
                    </div>
                    <Popover.Panel
                      className="absolute lg:relative left-0 right-0 top-full"
                      static={isDesktop}
                    >
                      <nav className={`flex flex-col lg:flex-row lg:space-x-10 lg:items-start bg-[#f1f1f1] lg:bg-inherit text-black lg:pt-0 uppercase ${
                          isMobile ? 'max-h-[550px] overflow-y-auto' : ''
                        }`}>
                        {navigationDesktop.map((item, index) => {
                          return (
                            <MenuItemContainer
                              item={item}
                              key={index}
                              uniqueKey={index}
                            />
                          );
                        })}
                      </nav>
                    </Popover.Panel>
                    {
                      <>
                        <div
                          className="relative  ml-[3rem] 2xl:ml-[6rem] mr-[0.5rem] lg:mr-[12px] pb-5"
                          onMouseEnter={() => handlePopDesktop(true)}
                          onMouseLeave={() => handlePopDesktop(false)}
                        >
                          <img
                            src="/icons/user.svg"
                            alt="Users"
                            className="min-w-[1.5rem] max-w-[1.5rem] w-[1.5rem] h-[1.5rem] md:w-6 md:h-6 aspect-1 
                            mt-[20px]"
                            onClick={handlePop}
                          />
                          <ul
                            className={`absolute profile-pop  top-[50px] md:top-[50px]  flex-col left-0 w-[130px] md:w-[150px] h-auto  bg-white  ${
                              isPopShow ? 'flex' : 'hidden'
                            }`}
                          >
                            {!isSignedIn ? (
                              <li
                                className="text-base font-medium px-3 text-[#495057] py-2 md:py-4 md:text-[16px] md:leading-6 cursor-pointer"
                                onClick={handleAuthModal}
                              >
                                Login
                              </li>
                            ) : (
                              <li className="text-base font-medium text-[#495057] md:text-[16px] md:leading-6">
                                <Link
                                  to="/account/my-profile"
                                  className="block w-full py-2 md:py-4 px-3 hover:underline"
                                >
                                  My Profile
                                </Link>
                              </li>
                            )}
                            <li className="text-base font-medium text-[#495057] md:text-[16px] md:leading-6">
                              <Link
                                to="/account/orders"
                                className="block w-full py-2 md:py-4 px-3 hover:underline"
                                onClick={(e) => {
                                  if(!isSignedIn){
                                    e.preventDefault()
                                    setHasToRedirect({redirectUrl: '/account/orders', allow:false})
                                    handleAuthModal()
                                  }
                                }}
                              >
                                Track Your Order
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div
                          onClick={handleSearchClick}
                          className="cursor-pointer  mr-[0.5rem] lg:mr-[7px]"
                        >
                          <img
                            src="/icons/header-search.svg"
                            alt="Search"
                            className="w-6 h-6 min-h-[1.5rem] min-w-[1.5rem] aspect-1"
                          />
                        </div>
                        {!isMgLanding && !isCeilingFanLanding ? (
                          <div className="flex items-center lg:ml-2">
                            <CartTrigger />

                            <Popover.Button className="hamburgerIcon lg:hidden rounded-md p-2 inline-flex items-center justify-center text-white">
                              <span className="sr-only">Open menu</span>

                              <MenuIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </Popover.Button>
                          </div>
                        ) : null}
                      </>
                    }
                  </>
                )}
              </Popover>
            )}

            {/* This will be show only on checkout page start */}
            <div
              className="md:divide-x md:divide-white text-white text-sm text-right"
              hidden={!isCheckout}
            >
              <span className="inline-flex items-center md:pr-3">
                <MailIcon width="20" />{' '}
                <a href="mailto:support@atomberg.com">support@atomberg.com</a>
              </span>
              <span className="pl-3 inline-flex items-center">
                <PhoneIcon width="16" />{' '}
                <a href="tel:+918448449442">+91 844-844-9442</a>
              </span>
            </div>
            {/* This will be show only on checkout page end */}
          </div>
        </div>
        <div
          className={`search-container bg-[#343434] w-full py-3 ${
            isSearchShow ? 'block' : 'hidden'
          }`}
        >
          <div className="w-[90%] md:w-[60%] mx-auto">
            <GlobalSearch handleSearchClose={handleSearchClose} />
          </div>
        </div>
      </header>

      <AuthModal
        showModal={isOpen}
        setOpenModal={handleClose}
        isCheckout={isCheckout}
      />
    </>
  );
}
