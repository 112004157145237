import defaultOperations from 'src/queries/getConfigs.gql';
import { useItems } from './useItems';

export const useConfigs = () => {
  const { GET_CONFIGS } = defaultOperations;

  const { data: configs } = useItems({
    query: GET_CONFIGS,
  });

  const { storeConfig } = configs || {};

  return {
    storeConfig,
  };
};
